import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/insurancePayment";

export async function searchInsurancePayments(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getInsurancePayment(paymentId) {
  return await ApiService.get(url + "/" + paymentId)
    .then((res) => {
      const item = res.data;
      return item;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addInsurancePayment(payment) {
  return await ApiService.post(url, payment as never)
  .then((res) => {
    return res.data;
  })
  .catch(({ response }) => {
    processErrors(response);
  });
}

export async function updateInsurancePayment(payment) {
  if (payment) {
    return await ApiService.put(url + "/" + payment.id, payment as never)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteInsurancePayment(payment) {
  if (payment) {
    await ApiService.delete(url + "/" + payment?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function postInsurancePayment(payment) {
  if (payment) {
    return await ApiService.post(url + "/post", payment as never)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}